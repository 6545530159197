import * as React from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import * as Routes from 'lib/routes';
import I18n from 'lib/I18n';

import * as duck from './duck';
import * as style from './style.scss';

interface SessionExpiredDialogProps {
  session: duck.models.Session;
}

export { models } from './duck';
export default class SessionExpiredDialog
extends React.Component<SessionExpiredDialogProps, {}> {
  state = {
    loginRequired: false,
  };

  componentWillReceiveProps(nextProps: SessionExpiredDialogProps) {
    this.setState({ loginRequired: !!(nextProps.session.loginRequired) });
  }

  onRedirectToLogin = () => {
    location.href = Routes.user_session_path();
  }

  onClose = () => {
    this.setState({ loginRequired: false });
  }

  render() {
    return (
      <Modal
        className="dialog-session-expired"
        isOpen={this.state.loginRequired}
        onClosed={this.onClose}
        contentClassName={style.content}
      >
        <ModalBody>
          {I18n.t('session_expired_dialog.message')}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onRedirectToLogin}>
            {I18n.t('session_expired_dialog.login')}
          </Button>
          <Button onClick={this.onClose}>
            {I18n.t('session_expired_dialog.close')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
