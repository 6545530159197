import * as React from 'react';
import { connect } from 'react-redux';
import I18n from 'lib/I18n';
import { ConfirmModal } from '../ConfirmModal';
import { InitialStatesType } from 'bundles/ApplicationSettingUploader/reducers';
import * as models from '../../models';
import { bindActionCreators } from 'redux';
import * as app from '../../ducks/app';

interface AppProps {
  app: models.App;
}

interface AppDispatchProps {
  onReadFile: (file: File) => void;
  onOpen: () => void;
  onClose: () => void;
}

class App extends React.PureComponent<AppProps & AppDispatchProps> {
  inputRef?: HTMLElement;

  onFileChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files || e.currentTarget.files.length === 0) {
      return;
    }
    const file = e.currentTarget.files[0];
    this.props.onReadFile(file);
  }

  onOpen = () => {
    this.props.onOpen();
  }

  onClose = () => {
    this.props.onClose();
  }

  onUpload = () => {
    if (!this.inputRef) {
      return;
    }
    // 上位のFormを探してsubmit
    let parent = this.inputRef.parentElement;
    while (parent) {
      if (parent instanceof HTMLFormElement) {
        parent.submit();
        return;
      }
      parent = parent.parentElement;
    }
  }

  setInputRef = (e: any) => {
    this.inputRef = e;
  }

  render() {
    return (
      <>
        <input ref={this.setInputRef} type="file" name="file" onChange={this.onFileChange} />
        <input
          type="button"
          className="btn btn-info me-2"
          value={I18n.t('utility.application_setting.index.upload')}
          onClick={this.onOpen}
          disabled={this.props.app.csv.size === 0}
        />
        <ConfirmModal open={this.props.app.open} onUpload={this.onUpload} onClose={this.onClose}>
          <table className="table table-striped table-bordered table-sm mw-60rem">
            <thead>
              <tr>
                <th>{I18n.t('utility.application_setting.index.setting_index')}</th>
                <th>{I18n.t('utility.application_setting.index.key')}</th>
                <th>{I18n.t('utility.application_setting.index.value')}</th>
                <th>{I18n.t('utility.application_setting.index.description')}</th>
              </tr>
            </thead>
            <tbody>
              {this.props.app.csv.map((r, i) =>
                <tr key={i}>
                  {r.map((c, j) => <td key={j}>{c}</td>)}
                </tr>,
              )}
            </tbody>
          </table>
        </ConfirmModal>
      </>
    );
  }
}

function mapStateToProps(states: InitialStatesType): AppProps {
  return { app: states.app };
}

function mapDispatchToProps(dispatch: any): AppDispatchProps {
  const bindedActions = bindActionCreators(app.actions, dispatch);
  return {
    onReadFile: bindedActions.readFile,
    onOpen: bindedActions.open,
    onClose: bindedActions.close,
  };
}

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export {
  App,
  AppContainer,
};
