import * as Immutable from 'immutable';

import ImmutableTypedRecord from 'lib/ImmutableTypedRecord';

import Item from './Item';
import { Role } from '../constants';

export default class Unregistered extends ImmutableTypedRecord<{
  isLoading: boolean;
  isCreating: boolean;
  alertShow: boolean;
  users: Immutable.List<Item>;
  ndids: string;
  role: string;
  loginidFilter:string;
}>({
  isLoading: false,
  isCreating: false,
  alertShow: false,
  users: Immutable.List(),
  ndids: '',
  role: Role.owner,
  loginidFilter: '',
}) {
}
