import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Label, Input, FormGroup, FormText } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn, SelectRow } from 'react-bootstrap-table';

import Loader from 'lib/components/Loader';
import RolePopup from 'lib/components/RolePopup';
import I18n from 'lib/I18n';
import * as style from './style.scss';

import * as registered from '../../ducks/registered';
import * as models from '../../models';
import { InitialStatesType } from '../../reducers';
import { Roles, DisabledRoles } from '../../constants';

const keys = [
  'name',
  'loginid',
  'titleRole',
];

const flexItemStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
};

interface RegisteredPaneProps {
  app: models.App;
  registered: models.Registered;
}

interface RegisteredPaneDispatchProps {
  onGetUsersRequest(): void;
  onSelectUsers(payload: registered.SelectUsersPayload): void;
  onChangeRole(role: string): void;
  onChangeAlertShow(show: boolean): void;
  onUpdateRolesRequest(): void;
  onDeleteRolesRequest(): void;
}

class RegisteredPane
  extends React.PureComponent<RegisteredPaneProps & RegisteredPaneDispatchProps> {
  onRowSelect = (row: { loginid: string }, isSelected: boolean) => {
    this.props.onSelectUsers({ isSelected, loginids: [row.loginid] });
    return true;
  }

  onSelectAll = (isSelected: boolean, rows: { loginid: string }[]) => {
    this.props.onSelectUsers({ isSelected, loginids: rows.map(row => row.loginid) });
    return true;
  }

  onChangeRole = (e: React.FormEvent<HTMLInputElement>) => {
    this.props.onChangeRole(e.currentTarget.value);
  }

  onForceUpdateRoles = () => {
    this.props.onUpdateRolesRequest();
  }

  onUpdateRoles = () => {
    this.props.onUpdateRolesRequest();
  }

  onDeleteRoles = () => {
    /* eslint-disable no-alert */
    if (confirm(I18n.t('user_title_role_editor.messages.confirm_deletion'))) {
      /* eslint-enable no-alert */
      this.props.onDeleteRolesRequest();
    }
  }

  renderTable() {
    const tempRoles: { [index: string]: string } = {};
    Roles.forEach((role) => {
      tempRoles[role] = role;
    });
    const columns = keys.map((key, i) => {
      if (key === 'titleRole') {
        return (
          <TableHeaderColumn
            dataField="titleRole"
            key={i}
            filter={{
              type: 'SelectFilter',
              placeholder: `${I18n.t('user_title_role_editor.label.role')}...`,
              options: tempRoles,
            }}
          >
            {I18n.t('user_title_role_editor.label.role')}
          </TableHeaderColumn>
        );
      }
      return (
        <TableHeaderColumn
          dataField={key}
          key={0}
          filter={{
            type: 'TextFilter',
            placeholder: `${I18n.t(`user_title_role_editor.label.${key}`)}...`,
          }}
        >
          {I18n.t(`user_title_role_editor.label.${key}`)}
        </TableHeaderColumn>
      );
    });

    const users: any[] = this.props.registered.users.toJS();

    const selectRowProp: SelectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      bgColor: '#d9edf7',
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      selected: users.filter(user => user.isSelected).map(user => user.loginid),
    };

    return (
      <BootstrapTable
        data={users}
        version="5"
        striped
        hover
        condensed
        pagination
        keyField="loginid"
        selectRow={selectRowProp}
        tableStyle={flexItemStyle}
        headerStyle={{ overflow: 'visible' }}
        containerStyle={flexItemStyle}
        bodyStyle={{ overflow: 'auto' }}
      >
        {columns}
      </BootstrapTable>
    );
  }


  render() {
    const canModify = this.props.registered.users.some(user => user.isSelected)
      && !this.props.registered.isModifying && !this.props.registered.isLoading;

    return (
      <div className={style.root}>
        <FormGroup style={flexItemStyle}>
          <span className="font-weight-bold">
            {I18n.t('user_title_role_editor.label.registered_users')}
          </span>
          <p>{I18n.t('user_title_role_editor.messages.registered')}</p>
          <Loader
            show={this.props.registered.isLoading}
            style={flexItemStyle}
            contentStyle={flexItemStyle}
          >
            {this.renderTable()}
          </Loader>
        </FormGroup>
        <FormGroup>
          <Label className="me-2">
            {I18n.t('user_title_role_editor.label.role')}
          </Label>
          <RolePopup placement="top" />
          <Input type="select" onChange={e => this.onChangeRole(e as any)}>
            {Roles.map((role, i) =>
              <option
                value={role}
                key={i}
                disabled={DisabledRoles.indexOf(role) >= 0}
              >
                {role}
                {DisabledRoles.indexOf(role) >= 0 &&
                  I18n.t('user_title_role_editor.label.disabled_role')}
              </option>,
            )}
          </Input>
          <FormText>{I18n.t('user_title_role_editor.messages.role_registered')}</FormText>
        </FormGroup>
        <FormGroup>
          <Button
            color="primary"
            onClick={this.onUpdateRoles}
            disabled={!canModify}
            className="me-3"
          >
            {I18n.t('user_title_role_editor.action.update')}
          </Button>
          <Button
            color="danger"
            onClick={this.onDeleteRoles}
            disabled={!canModify}
          >
            {I18n.t('user_title_role_editor.action.delete')}
          </Button>
        </FormGroup>
      </div>
    );
  }

  componentDidMount() {
    this.props.onGetUsersRequest();
  }
}

function mapStateToProps(state: InitialStatesType): RegisteredPaneProps {
  return {
    app: state.app,
    registered: state.registered,
  };
}

function mapDispatchToProps(dispatch: any): RegisteredPaneDispatchProps {
  const bindedActions = bindActionCreators(registered.actions, dispatch);
  return {
    onGetUsersRequest: bindedActions.getUsersRequest,
    onSelectUsers: bindedActions.selectUsers,
    onChangeRole: bindedActions.changeRole,
    onChangeAlertShow: bindedActions.changeAlertShow,
    onUpdateRolesRequest: bindedActions.updateRolesRequest,
    onDeleteRolesRequest: bindedActions.deleteRolesRequest,
  };
}

const RegisteredPaneContainer = connect(mapStateToProps, mapDispatchToProps)(RegisteredPane);

export {
  RegisteredPane,
  RegisteredPaneContainer,
};
