import * as uuid from 'uuid';
import ActionReducer from 'action-reducer';

import ImmutableTypedRecord from 'lib/ImmutableTypedRecord';
import pick from 'lib/pick';

// Local Constants
const LEVEL_SUCCESS = 'success';
const LEVEL_ERROR = 'error';

// Actions
export const NOTIFY_SUCCESS = 'lib/notification/NOTIFY_SUCCESS';
export const NOTIFY_ERROR = 'lib/notification/NOTIFY_ERROR';

// Models
export namespace models {
  export class Notification extends ImmutableTypedRecord<{
    message: string;
    level: string | undefined;
    notificationId: string | undefined;
    title: string | undefined;
    timeout: number;
    position: string;
  }>({
    message: '',
    level: undefined,
    notificationId: undefined,
    title: undefined,
    timeout: 0,
    position: 'br',
  }) {
    getOptions() {
      return pick(this, [
        'level',
        'title',
        'timeout',
        'position',
      ]).toObject();
    }
  }
}

const { createAction, reducer } = ActionReducer(new models.Notification());
export default reducer;

export const actions = {
  notifySuccess: createAction(
    NOTIFY_SUCCESS,
    (state, message: string) => state.merge({
      message,
      level: LEVEL_SUCCESS,
      notificationId: uuid.v4(),
      timeout: 5, // seconds
    }),
  ),
  notifyError: createAction(
    NOTIFY_ERROR,
    (state, message: string) => state.merge({
      message,
      level: LEVEL_ERROR,
      notificationId: uuid.v4(),
    }),
  ),
};

