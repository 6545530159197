import * as immutable from 'immutable';

export function keyIn(keys: string[]) {
  const keySet = immutable.Set(keys);
  return ((v: any, k: string) => keySet.has(k));
}

/**
 * 指定したキーでフィルタしたimmutable.Mapのサブセットを返す
 * _.pick(map, ['a', 'b']) に類似した動作
 * @param {immutable.Map} map immutable.Mapのインスタンス
 * @param {Array.<string>} keys キーとして指定する文字列のArray
 * @return {immutable.Map}
 */
export default function pick(map: immutable.Map<any, any>, keys: string[]) {
  return map.filter(keyIn(keys));
}
