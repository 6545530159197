import ReactOnRails from 'react-on-rails';
import Notification from 'lib/components/Notification';
import * as duck from 'lib/components/Notification/duck';
import * as React from 'react';

interface NotificationWrapperProps {
  message: string;
  level: string;
  title: string;
}

const NotificationWrapper = (props: NotificationWrapperProps) => {
  const notification = new duck.models.Notification(props);
  return (
    <Notification notification={notification} />
  );
};

ReactOnRails.register({ NotificationApp: NotificationWrapper });
