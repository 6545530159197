import * as React from 'react';
import { Button, Popover, PopoverBody, Table, PopoverProps } from 'reactstrap';
import Icon from 'lib/components/Icon';

import * as style from './style.scss';
import I18n from 'lib/I18n';

import Popper from 'popper.js';

interface RolePopupProps {
  placement: Popper.Placement;
  prefixElement?: JSX.Element;
  suffixElement?: JSX.Element;
}

export default class RolePopup extends React.PureComponent<RolePopupProps> {
  state = {
    // idを自動生成
    id: `RolePopup-${(Math.random() * Number.MAX_SAFE_INTEGER).toFixed().toString()}`,
    popoverOpen: false,
  };

  toggle = () => {
    this.setState({
      ...this.state,
      popoverOpen: !this.state.popoverOpen,
    });
  }

  render() {
    return (
      <div className="d-inline">
        <Button id={this.state.id} size="sm" onClick={this.toggle} color="info" outline >
          <Icon name="question" />
        </Button>
        <Popover
          placement={this.props.placement}
          target={this.state.id}
          isOpen={this.state.popoverOpen}
          toggle={this.toggle}
          className={style.inner}
        >
          <PopoverBody>
            {this.props.prefixElement}
            <Table>
              <thead>
                <tr>
                  <th>{I18n.t('role_popup.label.role')}</th>
                  <th>{I18n.t('role_popup.label.description')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>owner</td>
                  <td>{I18n.t('role_popup.message.owner_description')}</td>
                </tr>
                <tr>
                  <td>developer</td>
                  <td>{I18n.t('role_popup.message.developer_description')}</td>
                </tr>
                <tr>
                  <td>observer</td>
                  <td>
                    <p>{I18n.t('role_popup.message.observer_description1')}</p>
                    <p>
                      {I18n.t('role_popup.message.observer_description2')}
                      <div className="text-warning">
                        {I18n.t('role_popup.message.observer_description3')}
                      </div>
                      <div>
                        {I18n.t('role_popup.message.observer_description4')}
                      </div>
                      <div>
                        {I18n.t('role_popup.message.observer_description5')}
                      </div>
                    </p>
                  </td>
                </tr>
              </tbody>
            </Table>
            {this.props.suffixElement}
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}
