import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import I18n from 'lib/I18n';

interface ConfirmModalProps {
  open: boolean;
  children: React.ReactNode;
  onUpload: () => void;
  onClose: () => void;
}

export class ConfirmModal extends React.PureComponent<ConfirmModalProps> {
  onClose = () => {
    this.props.onClose();
  }

  onUpload = () => {
    this.props.onUpload();
    this.props.onClose();
  }

  render() {
    return (
      <Modal isOpen={this.props.open}>
        <ModalHeader toggle={this.onClose}>
          {I18n.t('utility.application_setting.index.upload')}
        </ModalHeader>
        <ModalBody>{this.props.children}</ModalBody>
        <ModalFooter>
          <input
            type="button"
            className="btn btn-primary"
            value={I18n.t('utility.application_setting.index.upload')}
            onClick={this.onUpload}
          />
          <input
            type="button"
            className="btn btn-secondary"
            value={I18n.t('helpers.links.cancel')}
            onClick={this.onClose}
          />
        </ModalFooter>
      </Modal>
    );
  }
}
