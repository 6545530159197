/// <reference path="./custom.d.ts" />
/* eslint-disable */
/**
 * NMAS2
 * Web API for NMAS2
 *
 * OpenAPI spec version: 0.0.1
 *
 * NOTE: This class is auto generated by the Swaggable code generator program.
 * http://ndo-gitlab.boy.nintendo.co.jp/kawahara_taisuke/rails-swaggable/
 * Do not edit the class manually.
 *
 * Template of this code is based on swagger-codegen typescript-fetch's template.
 * https://github.com/swagger-api/swagger-codegen/tree/master/modules/swagger-codegen/src/main/resources/typescript-fetch
 * Apache License 2.0
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";

import * as changeObject from "change-object-case";
changeObject.options.recursive = true;
changeObject.options.arrayRecursive = true;

function transformKeysToCamelCase(object: any) {
  if (object instanceof Array) {
    return object.map(o => changeObject.camelKeys(o));
  } else {
    return changeObject.camelKeys(object);
  }
}

export interface FetchAPI { (url: string, init?: any): Promise<Response>; }

const BASE_PATH = "/api".replace(/\/+$/, "");
const COLLECTION_FORMATS = {
  'csv': ',',
  'tsv': '   ',
  'ssv': ' ',
  'pipes': '|'
}

export type ApiKey = string | ((name: string) => string);

export interface FetchArgs {
  url: string;
  options: any;
}

export class UnexpectedResponseError extends Error {
  public name = "UnexpectedResponseError"
  constructor(public response: Response, msg?: string) {
    super(msg);
  }
}

export interface ApiResponse {
  code: number | null;
  ok: boolean;
  body: any;
}

function getRailsCsrfToken(): string | null {
  if (document == null) {
    return null;
  }
  const meta = document.querySelector('meta[name=csrf-token]');
  return meta ? meta.getAttribute('content') : null;
}

/**
 * エラーオブジェクト
 */
export interface ApiError {
  /**
   * エラーの概要。対人可読形式の短い文章であること
   */
  title: string;
  /**
   * 対人可読形式のエラーの説明
   */
  detail?: string;
  /**
   * エラーの発生要因を示すオブジェクト
   */
  source?: {
    /**
     * エラーの発生箇所を示すエンティティ(バリデーションエラーであれば属性名を指す)
     */
    pointer: string;
    /**
     * エラーを発生させた具体的な値
     */
    parameter?: string;
  };
  /**
   * HTTPステータスコードの文字列表現
   */
  status?: string;
  /**
   * アプリ固有のエラーコードの文字列表現
   */
  code?: string;
  /**
   * このエラーに関する非定型的な情報を含むオブジェクト
   */
  meta?: { [key: string]: any };
}

export interface CreateUserTitleRolePayload {
  user: {
    role: string;
    ndids: string;
    uids: Array<string>;
  };
}

export interface CreateUserTitleRoleResponse200 {
  message: string;
}

export interface CreateUserTitleRoleResponse500 {
  message: string;
}

export interface DestroyUserTitleRoleResponse200 {
  message: string;
}

export interface DestroyUserTitleRoleResponse500 {
  message: string;
}

export interface UpdateUserTitleRolePayload {
  user: {
    role: string;
    ids: Array<number>;
  };
}

export interface UpdateUserTitleRoleResponse200 {
  message: string;
}

export interface UpdateUserTitleRoleResponse500 {
  message: string;
}

export interface UserWithTitleRole {
  id: number;
  loginid: string;
  name: string;
  uid: string;
  titleRole: string;
}
/**
 * UserTitleRolesApi - fetch parameter creator
 */
export const UserTitleRolesApiFetchParamCreator = {
    /**
     *
     * @param commonTitleUuid
     * @param body
     * @param {*} [options] Override http request option.
     */
    createUserTitleRole(
      commonTitleUuid: string,
      body: CreateUserTitleRolePayload,
      options: any = {},
    ): FetchArgs {
      const httpMethod: string = 'POST';
      const localVarPath = `/v1/users/common_titles/{common_title_uuid}/roles`
        .replace(`{${"common_title_uuid"}}`, encodeURIComponent(String(commonTitleUuid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: httpMethod, credentials: 'same-origin' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const railsCsrfToken = getRailsCsrfToken();
      if (httpMethod !== 'GET' && railsCsrfToken != null) {
        localVarHeaderParameter['X-CSRF-Token'] = railsCsrfToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      localVarRequestOptions.body = JSON.stringify(changeObject.snakeKeys(body) || {});

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param commonTitleUuid
     * @param params.user.ids
     * @param {*} [options] Override http request option.
     */
    destroyUserTitleRole(
      commonTitleUuid: string,
      params: {
        user: {
          ids: Array<number>;
        }      },
      options: any = {},
    ): FetchArgs {
      const httpMethod: string = 'DELETE';
      const localVarPath = `/v1/users/common_titles/{common_title_uuid}/roles`
        .replace(`{${"common_title_uuid"}}`, encodeURIComponent(String(commonTitleUuid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: httpMethod, credentials: 'same-origin' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const railsCsrfToken = getRailsCsrfToken();
      if (httpMethod !== 'GET' && railsCsrfToken != null) {
        localVarHeaderParameter['X-CSRF-Token'] = railsCsrfToken;
      }

      if (params != null && params.user != null && params.user.ids != null) {
        localVarQueryParameter['user[ids][]'] = params.user.ids
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param commonTitleUuid
     * @param {*} [options] Override http request option.
     */
    registeredUserTitleRole(
      commonTitleUuid: string,
      options: any = {},
    ): FetchArgs {
      const httpMethod: string = 'GET';
      const localVarPath = `/v1/users/common_titles/{common_title_uuid}/registered`
        .replace(`{${"common_title_uuid"}}`, encodeURIComponent(String(commonTitleUuid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: httpMethod, credentials: 'same-origin' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const railsCsrfToken = getRailsCsrfToken();
      if (httpMethod !== 'GET' && railsCsrfToken != null) {
        localVarHeaderParameter['X-CSRF-Token'] = railsCsrfToken;
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param commonTitleUuid
     * @param params.loginidFilter
     * @param {*} [options] Override http request option.
     */
    unregisteredUserTitleRole(
      commonTitleUuid: string,
      params: {
        loginidFilter: string;      },
      options: any = {},
    ): FetchArgs {
      const httpMethod: string = 'GET';
      const localVarPath = `/v1/users/common_titles/{common_title_uuid}/unregistered`
        .replace(`{${"common_title_uuid"}}`, encodeURIComponent(String(commonTitleUuid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: httpMethod, credentials: 'same-origin' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const railsCsrfToken = getRailsCsrfToken();
      if (httpMethod !== 'GET' && railsCsrfToken != null) {
        localVarHeaderParameter['X-CSRF-Token'] = railsCsrfToken;
      }

      if (params != null && params.loginidFilter != null) {
        localVarQueryParameter['loginid_filter'] = params.loginidFilter
      }

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param commonTitleUuid
     * @param body
     * @param {*} [options] Override http request option.
     */
    updateUserTitleRole(
      commonTitleUuid: string,
      body: UpdateUserTitleRolePayload,
      options: any = {},
    ): FetchArgs {
      const httpMethod: string = 'PUT';
      const localVarPath = `/v1/users/common_titles/{common_title_uuid}/roles`
        .replace(`{${"common_title_uuid"}}`, encodeURIComponent(String(commonTitleUuid)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: httpMethod, credentials: 'same-origin' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      const railsCsrfToken = getRailsCsrfToken();
      if (httpMethod !== 'GET' && railsCsrfToken != null) {
        localVarHeaderParameter['X-CSRF-Token'] = railsCsrfToken;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      localVarRequestOptions.body = JSON.stringify(changeObject.snakeKeys(body) || {});

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
};
export const ClientFactory = (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH, apiKey?: ApiKey) => ({
  /**
   * UserTitleRolesApi
   */
  UserTitleRolesApi: {
    /**
     *
     * @param commonTitleUuid
     * @param body
     * @param {*} [options] Override http request option.
     */
    async createUserTitleRole(
      commonTitleUuid: string,
      body: {
        user: {
          role: string;
          ndids: string;
          uids: Array<string>;
        };
      },
      options: any = {},
      ): Promise<UserTitleRolesApi.CreateUserTitleRoleResponse> {
      const fetchArgs = UserTitleRolesApiFetchParamCreator.createUserTitleRole(
        commonTitleUuid,
        body,
        options,
      );
      const response = await fetch(basePath + fetchArgs.url, fetchArgs.options);
      switch (response.status) {
        case 200:
          return { code: 200, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        case 401:
          return { code: 401, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        case 403:
          return { code: 403, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        case 500:
          return { code: 500, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        default:
          return { code: null, ok: false, body: new UnexpectedResponseError(response) };
      }
    },
    /**
     *
     * @param commonTitleUuid
     * @param params.user.ids
     * @param {*} [options] Override http request option.
     */
    async destroyUserTitleRole(
      commonTitleUuid: string,
      params: {
        user: {
          ids: Array<number>;
        }      },
      options: any = {},
      ): Promise<UserTitleRolesApi.DestroyUserTitleRoleResponse> {
      const fetchArgs = UserTitleRolesApiFetchParamCreator.destroyUserTitleRole(
        commonTitleUuid,
        params,
        options,
      );
      const response = await fetch(basePath + fetchArgs.url, fetchArgs.options);
      switch (response.status) {
        case 200:
          return { code: 200, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        case 401:
          return { code: 401, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        case 403:
          return { code: 403, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        case 500:
          return { code: 500, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        default:
          return { code: null, ok: false, body: new UnexpectedResponseError(response) };
      }
    },
    /**
     *
     * @param commonTitleUuid
     * @param {*} [options] Override http request option.
     */
    async registeredUserTitleRole(
      commonTitleUuid: string,
      options: any = {},
      ): Promise<UserTitleRolesApi.RegisteredUserTitleRoleResponse> {
      const fetchArgs = UserTitleRolesApiFetchParamCreator.registeredUserTitleRole(
        commonTitleUuid,
        options,
      );
      const response = await fetch(basePath + fetchArgs.url, fetchArgs.options);
      switch (response.status) {
        case 200:
          return { code: 200, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        case 401:
          return { code: 401, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        case 403:
          return { code: 403, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        default:
          return { code: null, ok: false, body: new UnexpectedResponseError(response) };
      }
    },
    /**
     *
     * @param commonTitleUuid
     * @param params.loginidFilter
     * @param {*} [options] Override http request option.
     */
    async unregisteredUserTitleRole(
      commonTitleUuid: string,
      params: {
        loginidFilter: string;      },
      options: any = {},
      ): Promise<UserTitleRolesApi.UnregisteredUserTitleRoleResponse> {
      const fetchArgs = UserTitleRolesApiFetchParamCreator.unregisteredUserTitleRole(
        commonTitleUuid,
        params,
        options,
      );
      const response = await fetch(basePath + fetchArgs.url, fetchArgs.options);
      switch (response.status) {
        case 200:
          return { code: 200, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        case 401:
          return { code: 401, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        case 403:
          return { code: 403, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        case 500:
          return { code: 500, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        default:
          return { code: null, ok: false, body: new UnexpectedResponseError(response) };
      }
    },
    /**
     *
     * @param commonTitleUuid
     * @param body
     * @param {*} [options] Override http request option.
     */
    async updateUserTitleRole(
      commonTitleUuid: string,
      body: {
        user: {
          role: string;
          ids: Array<number>;
        };
      },
      options: any = {},
      ): Promise<UserTitleRolesApi.UpdateUserTitleRoleResponse> {
      const fetchArgs = UserTitleRolesApiFetchParamCreator.updateUserTitleRole(
        commonTitleUuid,
        body,
        options,
      );
      const response = await fetch(basePath + fetchArgs.url, fetchArgs.options);
      switch (response.status) {
        case 200:
          return { code: 200, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        case 401:
          return { code: 401, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        case 403:
          return { code: 403, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        case 500:
          return { code: 500, ok: response.ok, body: transformKeysToCamelCase(await response.json()) };
        default:
          return { code: null, ok: false, body: new UnexpectedResponseError(response) };
      }
    },
  },
});

const defaultClient = ClientFactory();

export namespace UserTitleRolesApi {
  export type CreateUserTitleRoleResponse = { code: 200, ok: boolean, body: CreateUserTitleRoleResponse200 } | { code: 401, ok: boolean, body: Array<ApiError> } | { code: 403, ok: boolean, body: Array<ApiError> } | { code: 500, ok: boolean, body: CreateUserTitleRoleResponse500 } | { code: null, ok: boolean, body: UnexpectedResponseError };
  export type DestroyUserTitleRoleResponse = { code: 200, ok: boolean, body: DestroyUserTitleRoleResponse200 } | { code: 401, ok: boolean, body: Array<ApiError> } | { code: 403, ok: boolean, body: Array<ApiError> } | { code: 500, ok: boolean, body: DestroyUserTitleRoleResponse500 } | { code: null, ok: boolean, body: UnexpectedResponseError };
  export type RegisteredUserTitleRoleResponse = { code: 200, ok: boolean, body: Array<UserWithTitleRole> } | { code: 401, ok: boolean, body: Array<ApiError> } | { code: 403, ok: boolean, body: Array<ApiError> } | { code: null, ok: boolean, body: UnexpectedResponseError };
  export type UnregisteredUserTitleRoleResponse = { code: 200, ok: boolean, body: Array<UserWithTitleRole> } | { code: 401, ok: boolean, body: Array<ApiError> } | { code: 403, ok: boolean, body: Array<ApiError> } | { code: 500, ok: boolean, body: Array<ApiError> } | { code: null, ok: boolean, body: UnexpectedResponseError };
  export type UpdateUserTitleRoleResponse = { code: 200, ok: boolean, body: UpdateUserTitleRoleResponse200 } | { code: 401, ok: boolean, body: Array<ApiError> } | { code: 403, ok: boolean, body: Array<ApiError> } | { code: 500, ok: boolean, body: UpdateUserTitleRoleResponse500 } | { code: null, ok: boolean, body: UnexpectedResponseError };

  export const createUserTitleRole = defaultClient.UserTitleRolesApi.createUserTitleRole;
  export const destroyUserTitleRole = defaultClient.UserTitleRolesApi.destroyUserTitleRole;
  export const registeredUserTitleRole = defaultClient.UserTitleRolesApi.registeredUserTitleRole;
  export const unregisteredUserTitleRole = defaultClient.UserTitleRolesApi.unregisteredUserTitleRole;
  export const updateUserTitleRole = defaultClient.UserTitleRolesApi.updateUserTitleRole;
}
