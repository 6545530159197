// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//= require moment/moment
//= require moment/locale/ja
//= require moment-timezone/moment-timezone
//= require moment-timezone/builds/moment-timezone-with-data

// @ts-ignore
import { TempusDominus, extend } from '@eonasdan/tempus-dominus';
import moment from 'moment/moment';
import momentParse from '@eonasdan/tempus-dominus/dist/plugins/moment-parse.js';

window.moment = moment;
extend(momentParse, 'yyyy-MM-DD HH:mm:ssZ');

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll('[data-input="datetimepicker"]').forEach((datetimepickerElm) => {
    new TempusDominus(datetimepickerElm, {
      display: {
        toolbarPlacement: 'top',
      },
    });
  });
})
