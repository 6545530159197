import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Label, Input, FormGroup, FormText, InputGroup } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn, SelectRow } from 'react-bootstrap-table';
import Icon from 'lib/components/Icon';

import Loader from 'lib/components/Loader';
import RolePopup from 'lib/components/RolePopup';
import I18n from 'lib/I18n';
import * as style from './style.scss';

import * as unregistered from '../../ducks/unregistered';
import * as models from '../../models';
import { InitialStatesType } from '../../reducers';
import { Roles, DisabledRoles } from '../../constants';

const keys = [
  'name',
  'loginid',
];
const columns = keys.map((key, i) => (
  <TableHeaderColumn
    dataField={key}
    key={i}
    filter={{
      type: 'TextFilter',
      placeholder: `${I18n.t(`user_title_role_editor.label.${key}`)}...`,
    }}
  >
    {I18n.t(`user_title_role_editor.label.${key}`)}
  </TableHeaderColumn>
));

const flexItemStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
};

interface UnregisteredPaneProps {
  app: models.App;
  unregistered: models.Unregistered;
}

interface UnregisteredPaneDispatchProps {
  onGetUsersRequest(): void;
  onSelectUsers(payload: unregistered.SelectUsersPayload): void;
  onChangeNdids(ndids: string): void;
  onChangeRole(role: string): void;
  onChangeAlertShow(show: boolean): void;
  onCreateRolesRequest(): void;
  onChangeLoginidFilter(loginidFilter: string): void;
}

class UnregisteredPane
  extends React.PureComponent<UnregisteredPaneProps & UnregisteredPaneDispatchProps> {
  onSearch = () => {
    this.props.onGetUsersRequest();
  }

  onRowSelect = (row: { loginid: string }, isSelected: boolean) => {
    this.props.onSelectUsers({ isSelected, loginids: [row.loginid] });
    return true;
  }

  onSelectAll = (isSelected: boolean, rows: { loginid: string }[]) => {
    this.props.onSelectUsers({ isSelected, loginids: rows.map(row => row.loginid) });
    return true;
  }

  onChangeNdids = (e: React.FormEvent<HTMLInputElement>) => {
    this.props.onChangeNdids(e.currentTarget.value);
  }

  onChangeRole = (e: React.FormEvent<HTMLInputElement>) => {
    this.props.onChangeRole(e.currentTarget.value);
  }

  onForceCreateRoles = () => {
    this.props.onCreateRolesRequest();
    this.props.onChangeAlertShow(false);
  }

  onCreateRoles = () => {
    this.props.onCreateRolesRequest();
  }

  onChangeLoginidFilter = (e: React.FormEvent<HTMLInputElement>) => {
    this.props.onChangeLoginidFilter(e.currentTarget.value);
  }

  renderTable() {
    const users: any[] = this.props.unregistered.users.toJS();

    const selectRowProp: SelectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      bgColor: '#d9edf7',
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      selected: users.filter(user => user.isSelected).map(user => user.loginid),
    };

    return (
      <BootstrapTable
        data={users}
        version="5"
        striped
        hover
        condensed
        pagination
        keyField="loginid"
        selectRow={selectRowProp}
        tableStyle={flexItemStyle}
        headerStyle={{ overflow: 'visible' }}
        containerStyle={flexItemStyle}
        bodyStyle={{ overflow: 'auto' }}
      >
        {columns}
      </BootstrapTable>
    );
  }

  render() {
    const canCreate =
      (this.props.unregistered.ndids.length > 0 ||
        this.props.unregistered.users.some(user => user.isSelected))
      && !this.props.unregistered.isCreating && !this.props.unregistered.isLoading;

    return (
      <div className={style.root}>
        <FormGroup style={flexItemStyle}>
          <span className="font-weight-bold">
            {I18n.t('user_title_role_editor.label.unregistered_users')}
          </span>
          <p>{I18n.t('user_title_role_editor.messages.unregistered')}</p>
          <InputGroup>
            <Input
              type="text"
              value={this.props.unregistered.loginidFilter}
              placeholder="NDID"
              onChange={e => this.onChangeLoginidFilter(e as any)}
              onKeyDown={e => e.key === 'Enter' && this.onSearch()}
            />
          </InputGroup>
          <Loader
            show={this.props.unregistered.isLoading}
            style={flexItemStyle}
            contentStyle={flexItemStyle}
          >
            {this.renderTable()}
          </Loader>
        </FormGroup>
        <FormGroup>
          <Label>
            {I18n.t('user_title_role_editor.label.loginid')}
          </Label>
          <Input
            type="text"
            value={this.props.unregistered.ndids}
            placeholder={I18n.t('user_title_role_editor.placeholder.loginid')}
            onChange={e => this.onChangeNdids(e as any)}
          />
          <FormText>{I18n.t('user_title_role_editor.messages.ndid')}</FormText>
        </FormGroup>
        <FormGroup>
          <Label className="mr-2">
            {I18n.t('user_title_role_editor.label.role')}
          </Label>
          <RolePopup placement="top-end" />
          <Input type="select" onChange={e => this.onChangeRole(e as any)}>
            {Roles.map((role, i) =>
              <option
                value={role}
                key={i}
                disabled={DisabledRoles.indexOf(role) >= 0}
              >
                {role}
                {DisabledRoles.indexOf(role) >= 0 &&
                  I18n.t('user_title_role_editor.label.disabled_role')}
              </option>,
            )}
          </Input>
          <FormText>{I18n.t('user_title_role_editor.messages.role')}</FormText>
        </FormGroup>
        <FormGroup>
          <Button
            color="primary"
            onClick={this.onCreateRoles}
            disabled={!canCreate}
          >
            {I18n.t('user_title_role_editor.action.create')}
          </Button>
        </FormGroup>
      </div>
    );
  }
}

function mapStateToProps(state: InitialStatesType): UnregisteredPaneProps {
  return {
    app: state.app,
    unregistered: state.unregistered,
  };
}

function mapDispatchToProps(dispatch: any): UnregisteredPaneDispatchProps {
  const bindedActions = bindActionCreators(unregistered.actions, dispatch);
  return {
    onGetUsersRequest: bindedActions.getUsersRequest,
    onSelectUsers: bindedActions.selectUsers,
    onChangeNdids: bindedActions.changeNdids,
    onChangeRole: bindedActions.changeRole,
    onChangeAlertShow: bindedActions.changeAlertShow,
    onCreateRolesRequest: bindedActions.createRolesRequest,
    onChangeLoginidFilter: bindedActions.changeLoginidFilter,
  };
}

const UnregisteredPaneContainer = connect(mapStateToProps, mapDispatchToProps)(UnregisteredPane);

export {
  UnregisteredPane,
  UnregisteredPaneContainer,
};
