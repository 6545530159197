import * as Immutable from 'immutable';
import ImmutableTypedRecord from 'lib/ImmutableTypedRecord';

export class App extends ImmutableTypedRecord<{
  open: boolean;
  csv: Immutable.List<Immutable.List<string>>,
}>({
  open: false,
  csv: Immutable.List(),
}) {
}

