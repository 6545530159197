import * as Immutable from 'immutable';
import ActionReducer from 'action-reducer';

import * as Api from 'lib/Api';

import Item from '../models/Item';
import Unregistered from '../models/Unregistered';

const { createAction, reducer } = ActionReducer(new Unregistered());
export default reducer;

// Actions
export const GET_USERS_REQUEST = 'user-title-role-editor/unregistered/GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'user-title-role-editor/unregistered/GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'user-title-role-editor/unregistered/GET_USERS_FAILURE';
export const SELECT_USERS = 'user-title-role-editior/unregistered/SELECT_USERS';
export const CHANGE_NDIDS = 'user-title-role-editior/unregistered/CHANGE_NDIDS';
export const CHANGE_ROLE = 'user-title-role-editior/unregistered/CHANGE_ROLE';
export const CREATE_ROLES_REQUEST = 'user-title-role-editor/unregistered/CREATE_ROLES_REQUEST';
export const CREATE_ROLES_SUCCESS = 'user-title-role-editor/unregistered/CREATE_ROLES_SUCCESS';
export const CREATE_ROLES_FAILURE = 'user-title-role-editor/unregistered/CREATE_ROLES_FAILURE';
export const CHANGE_ALERT_SHOW = 'user-title-role-editor/unregistered/CHANGE_ALERT_SHOW';
export const CHANGE_LOGINID_FILTER = 'user-title-role-editor/unregistered/CHANGE_LOGINID_FILTER';

// Payload
export interface SelectUsersPayload {
  loginids: string[];
  isSelected: boolean;
}

export const actions = {
  getUsersRequest: createAction(
    GET_USERS_REQUEST,
    state => state.set('isLoading', true),
  ),
  getUsersSuccess: createAction(
    GET_USERS_SUCCESS,
    (state, payload: Api.UserWithTitleRole[]) => {
      const items = payload.map(item => new Item(item));
      return state.merge({
        isLoading: false,
        users: Immutable.List(items),
      });
    },
  ),
  getUsersFailure: createAction(
    GET_USERS_FAILURE,
    (state, p: Api.ApiResponse) => state.set('isLoading', false),
  ),
  createRolesRequest: createAction(
    CREATE_ROLES_REQUEST,
    state => state.set('isCreating', true),
  ),
  createRolesSuccess: createAction(
    CREATE_ROLES_SUCCESS,
    state => state.set('isCreating', false),
  ),
  createRolesFailure: createAction(
    CREATE_ROLES_FAILURE,
    (state, p: Api.ApiResponse) => state.set('isCreating', false),
  ),
  selectUsers: createAction(
    SELECT_USERS,
    (state, payload: SelectUsersPayload) => {
      const { loginids, isSelected } = payload;
      const loginIdsSet = new Set(loginids);
      return state.update('users', users =>
        users.map(user =>
          (loginIdsSet.has(user.loginid) ? user.set('isSelected', isSelected) : user),
        ).toList(),
      );
    },
  ),
  changeNdids: createAction(
    CHANGE_NDIDS,
    (state, ndids: string) => state.set('ndids', ndids),
  ),
  changeRole: createAction(
    CHANGE_ROLE,
    (state, role: string) => state.set('role', role),
  ),
  changeAlertShow: createAction(
    CHANGE_ALERT_SHOW,
    (state, payload: boolean) => state.set('alertShow', payload),
  ),
  changeLoginidFilter: createAction(
    CHANGE_LOGINID_FILTER,
    (state, payload: string) => state.set('loginidFilter', payload),
  ),
};
