// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".app-javascript-lib-components-Loader-style__loader--2fBNj{height:100%}", "",{"version":3,"sources":["/app/app/javascript/lib/components/Loader/style.scss"],"names":[],"mappings":"AAAA,2DACE,WAAA","file":"style.scss","sourcesContent":[".loader {\n  height: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"loader": "app-javascript-lib-components-Loader-style__loader--2fBNj"
};
module.exports = exports;
