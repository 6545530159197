// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".app-javascript-lib-components-SessionExpiredDialog-style__content--3kmCt{width:35rem}", "",{"version":3,"sources":["/app/app/javascript/lib/components/SessionExpiredDialog/style.scss"],"names":[],"mappings":"AAAA,0EACE,WAAA","file":"style.scss","sourcesContent":[".content {\n  width: 35rem;\n}\n"]}]);
// Exports
exports.locals = {
	"content": "app-javascript-lib-components-SessionExpiredDialog-style__content--3kmCt"
};
module.exports = exports;
