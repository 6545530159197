import * as React from 'react';

import { App } from '../../components/App';
import * as models from '../../models';

const RoleDescriptionViewerApp = (props: models.App) => {
  const reactComponent = (
    <App app={props} />
  );
  return reactComponent;
};

export default RoleDescriptionViewerApp;
