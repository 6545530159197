import * as React from 'react';
import * as models from '../../models';
import RolePopup from 'lib/components/RolePopup';

import I18n from 'lib/I18n';

interface AppProps {
  app: models.App;
}

class App extends React.PureComponent<AppProps> {
  renderPopoverMessage() {
    return (
      <b>
        {I18n.t('role_description_viewer.messages.description')}
      </b>
    );
  }

  render() {
    return (
      <span>
        <RolePopup
          prefixElement={this.renderPopoverMessage()}
          placement="right"
        />
      </span>
    );
  }
}

export {
  App,
};
