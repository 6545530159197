import { combineReducers } from 'redux';
import app from '../ducks/app';
import { App } from '../models';

const rootReducer = combineReducers({
  app,
});

type InitialStatesType = {
  app: App,
};

type InitialProps = {};

const initialStates = <InitialStatesType>rootReducer({}, { type: null });

export { rootReducer, initialStates, InitialStatesType, InitialProps };
