import ImmutableTypedRecord from 'lib/ImmutableTypedRecord';

export default class Item extends ImmutableTypedRecord<{
  id: number;
  loginid: string;
  name: string;
  titleRole: string;
  uid: string;
  isSelected: boolean;
}>({
  id: 0,
  loginid: '',
  name: '',
  titleRole: '',
  uid: '',
  isSelected: false,
}) {
}

