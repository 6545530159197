import ActionReducer from 'action-reducer';

import { App } from '../models/App';

const { createAction, reducer } = ActionReducer(new App());
export default reducer;

// Actions
export const READ_FILE = 'application-setting-uploader/app/READ_FILE';
export const READ_FILE_SUCCESS = 'application-setting-uploader/app/READ_FILE_SUCCESS';
export const OPEN = 'application-setting-uploader/app/OPEN';
export const CLOSE = 'application-setting-uploader/app/CLOSE';

// Payload

export const actions = {
  readFile: createAction<File>(
    READ_FILE,
    state => state,
  ),
  readFileSuccess: createAction<Immutable.List<Immutable.List<string>>>(
    READ_FILE_SUCCESS,
    (state, payload) => state.set('csv', payload),
  ),
  open: createAction(
    OPEN,
    state => state.set('open', true),
  ),
  close: createAction(
    CLOSE,
    state => state.set('open', false),
  ),
};
