// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".app-javascript-lib-components-RolePopup-style__inner--2xjqW{max-width:600px}", "",{"version":3,"sources":["/app/app/javascript/lib/components/RolePopup/style.scss"],"names":[],"mappings":"AAAA,6DACE,eAAA","file":"style.scss","sourcesContent":[".inner {\n  max-width: 600px;\n}\n"]}]);
// Exports
exports.locals = {
	"inner": "app-javascript-lib-components-RolePopup-style__inner--2xjqW"
};
module.exports = exports;
