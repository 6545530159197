import { combineReducers } from 'redux';
import * as models from '../models';
import app from '../ducks/app';
import unregistered from '../ducks/unregistered';
import registered from '../ducks/registered';
import session, { models as SessionModels } from 'lib/components/SessionExpiredDialog/duck';
import notification, { models as NotificationModels } from 'lib/components/Notification/duck';

const rootReducer = combineReducers({
  app,
  unregistered,
  registered,
  session,
  notification,
});

type InitialStatesType = {
  app: models.App,
  unregistered: models.Unregistered,
  registered: models.Registered,
  session: SessionModels.Session,
  notification: NotificationModels.Notification,
};

type InitialProps = {
  commonTitleUuid: string,
};

const initialStates = <InitialStatesType>rootReducer({}, { type: null });

export { rootReducer, initialStates, InitialStatesType, InitialProps };

