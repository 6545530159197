import * as React from 'react';
import ReactLoaderAdvanced from 'react-loader-advanced';
import * as style from './style.scss';

const defaultContentStyle = {
  height: '100%',
};

interface LoaderProps {
  children: any;
  contentStyle?: any;
  show: boolean;
  style?: React.CSSProperties;
}

const Loader = (props: LoaderProps) => {
  const contentStyle = Object.assign(defaultContentStyle, props.contentStyle || {});
  return (
    <ReactLoaderAdvanced
      className={style.loader}
      contentStyle={contentStyle}
      {...props}
    >
      {props.children}
    </ReactLoaderAdvanced>
  );
};

export default Loader;
