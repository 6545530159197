// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".app-javascript-bundles-UserTitleRoleEditor-components-RegisteredPane-style__root--200LI{margin:10px;display:flex;flex-direction:column;flex-grow:1}", "",{"version":3,"sources":["/app/app/javascript/bundles/UserTitleRoleEditor/components/RegisteredPane/style.scss"],"names":[],"mappings":"AAAA,yFACE,WAAA,CAEA,YAAA,CACA,qBAAA,CACA,WAAA","file":"style.scss","sourcesContent":[".root {\n  margin: 10px;\n\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "app-javascript-bundles-UserTitleRoleEditor-components-RegisteredPane-style__root--200LI"
};
module.exports = exports;
