import * as React from 'react';

export enum Styles {
  solid = 's',
  regular = 'r',
  light = 'l',
}

interface IconProps {
  name: string;
  style?: Styles;
  className?: string;
}

export default class Icon extends React.PureComponent<IconProps> {
  render() {
    const style = this.props.style || Styles.solid;
    const className = `fa${style} fa-${this.props.name} ${this.props.className}`.trimRight();
    return <i className={className} />;
  }
}
