import ActionReducer from 'action-reducer';

import App from '../models/App';

const { reducer } = ActionReducer(new App());
export default reducer;

// Actions

// Payload

export const actions = {
};
