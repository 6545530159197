/* eslint-disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /rails/info/properties(.:format) */
export function rails_info_properties_path(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info/routes(.:format) */
export function rails_info_routes_path(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "routes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info(.:format) */
export function rails_info_path(options?: object): string {
  return "/" + "rails" + "/" + "info" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/mailers(.:format) */
export function rails_mailers_path(options?: object): string {
  return "/" + "rails" + "/" + "mailers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** / */
export function root_path(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** /users/sign_in(.:format) */
export function new_user_session_path(options?: object): string {
  return "/" + "users" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/sign_in(.:format) */
export function user_session_path(options?: object): string {
  return "/" + "users" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/sign_out(.:format) */
export function destroy_user_session_path(options?: object): string {
  return "/" + "users" + "/" + "sign_out" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/auth/ndid(.:format) */
export function user_ndid_omniauth_authorize_path(options?: object): string {
  return "/" + "users" + "/" + "auth" + "/" + "ndid" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/auth/ndid/callback(.:format) */
export function user_ndid_omniauth_callback_path(options?: object): string {
  return "/" + "users" + "/" + "auth" + "/" + "ndid" + "/" + "callback" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/password/new(.:format) */
export function new_user_password_path(options?: object): string {
  return "/" + "users" + "/" + "password" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/password/edit(.:format) */
export function edit_user_password_path(options?: object): string {
  return "/" + "users" + "/" + "password" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/password(.:format) */
export function user_password_path(options?: object): string {
  return "/" + "users" + "/" + "password" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/cancel(.:format) */
export function cancel_user_registration_path(options?: object): string {
  return "/" + "users" + "/" + "cancel" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/sign_up(.:format) */
export function new_user_registration_path(options?: object): string {
  return "/" + "users" + "/" + "sign_up" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users/edit(.:format) */
export function edit_user_registration_path(options?: object): string {
  return "/" + "users" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /users(.:format) */
export function user_registration_path(options?: object): string {
  return "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /admin/sidekiq */
export function sidekiq_path(options?: object): string {
  return "/" + "admin" + "/" + "sidekiq" + $buildOptions(options, []);
}

/** /apidocs(.:format) */
export function apidocs_path(options?: object): string {
  return "/" + "apidocs" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /help(.:format) */
export function help_path(options?: object): string {
  return "/" + "help" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /platforms/:platform_code/common_titles/sync(.:format) */
export function sync_common_titles_path(platform_code: ScalarType, options?: object): string {
  return "/" + "platforms" + "/" + platform_code + "/" + "common_titles" + "/" + "sync" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["platform_code","format"]);
}

/** /platforms/:platform_code/common_titles/:common_title_uuid/users(.:format) */
export function common_title_users_path(platform_code: ScalarType, common_title_uuid: ScalarType, options?: object): string {
  return "/" + "platforms" + "/" + platform_code + "/" + "common_titles" + "/" + common_title_uuid + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["platform_code","common_title_uuid","format"]);
}

/** /platforms/:platform_code/common_titles/:common_title_uuid/companies(.:format) */
export function common_title_companies_path(platform_code: ScalarType, common_title_uuid: ScalarType, options?: object): string {
  return "/" + "platforms" + "/" + platform_code + "/" + "common_titles" + "/" + common_title_uuid + "/" + "companies" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["platform_code","common_title_uuid","format"]);
}

/** /platforms/:platform_code/common_titles/:common_title_uuid/companies/new(.:format) */
export function common_title_companies_new_path(platform_code: ScalarType, common_title_uuid: ScalarType, options?: object): string {
  return "/" + "platforms" + "/" + platform_code + "/" + "common_titles" + "/" + common_title_uuid + "/" + "companies" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["platform_code","common_title_uuid","format"]);
}

/** /platforms/:platform_code/common_titles(.:format) */
export function common_titles_path(platform_code: ScalarType, options?: object): string {
  return "/" + "platforms" + "/" + platform_code + "/" + "common_titles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["platform_code","format"]);
}

/** /titles/:id/sync_nex_servers(.:format) */
export function sync_nex_servers_title_path(id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + id + "/" + "sync_nex_servers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /titles/:id(.:format) */
export function title_path(id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id(.:format) */
export function title_info_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/matchmake/rating_range_threshold(.:format) */
export function matchmake_rating_range_threshold_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "matchmake" + "/" + "rating_range_threshold" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/matchmake/rating_range_threshold/new(.:format) */
export function matchmake_rating_range_threshold_new_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "matchmake" + "/" + "rating_range_threshold" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/matchmake/rating_range_threshold/:threshold(.:format) */
export function matchmake_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, threshold: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "matchmake" + "/" + "rating_range_threshold" + "/" + threshold + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","threshold","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/matchmake/matchmaking_server_setting(.:format) */
export function matchmake_matchmaking_server_setting_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "matchmake" + "/" + "matchmaking_server_setting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/matchmake/score_setting(.:format) */
export function matchmake_score_setting_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "matchmake" + "/" + "score_setting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/matchmake/score_setting/upload(.:format) */
export function matchmake_score_setting_upload_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "matchmake" + "/" + "score_setting" + "/" + "upload" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/matchmake/score_setting/download(.:format) */
export function matchmake_score_setting_download_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "matchmake" + "/" + "score_setting" + "/" + "download" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/matchmake/exclude_joined_matchmake_setting(.:format) */
export function matchmake_exclude_joined_matchmake_setting_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "matchmake" + "/" + "exclude_joined_matchmake_setting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/matchmake/override_ip_address_setting(.:format) */
export function matchmake_override_ip_address_setting_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "matchmake" + "/" + "override_ip_address_setting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/matchmake/override_ip_address_setting/new(.:format) */
export function matchmake_override_ip_address_setting_new_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "matchmake" + "/" + "override_ip_address_setting" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/matchmake/user_participated(.:format) */
export function matchmake_user_participated_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "matchmake" + "/" + "user_participated" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/matchmake/session(.:format) */
export function matchmake_session_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "matchmake" + "/" + "session" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/matchmake/persistent_gathering(.:format) */
export function matchmake_persistent_gathering_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "matchmake" + "/" + "persistent_gathering" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/matchmake/persistent_gathering/new(.:format) */
export function matchmake_persistent_gathering_new_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "matchmake" + "/" + "persistent_gathering" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking/category(.:format) */
export function ranking_category_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking" + "/" + "category" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking/category/:category_id/show(.:format) */
export function ranking_category_show_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, category_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking" + "/" + "category" + "/" + category_id + "/" + "show" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","category_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking/category/:category_id/edit(.:format) */
export function ranking_category_edit_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, category_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking" + "/" + "category" + "/" + category_id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","category_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking/category/:category_id/score(.:format) */
export function ranking_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, category_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking" + "/" + "category" + "/" + category_id + "/" + "score" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","category_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking/category/new(.:format) */
export function ranking_category_new_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking" + "/" + "category" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking/common_data(.:format) */
export function ranking_common_data_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking" + "/" + "common_data" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking/common_data/:common_data_id/show(.:format) */
export function ranking_common_data_show_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, common_data_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking" + "/" + "common_data" + "/" + common_data_id + "/" + "show" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","common_data_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking/common_data/:common_data_id/edit(.:format) */
export function ranking_common_data_edit_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, common_data_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking" + "/" + "common_data" + "/" + common_data_id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","common_data_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking/setting/edit(.:format) */
export function ranking_setting_edit_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking" + "/" + "setting" + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking/setting(.:format) */
export function ranking_setting_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking" + "/" + "setting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/category_setting(.:format) */
export function ranking2_category_setting_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "category_setting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/category_setting/:category_id/show(.:format) */
export function ranking2_category_setting_show_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, category_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "category_setting" + "/" + category_id + "/" + "show" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","category_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/category_setting/:category_id/category_type_setting(.:format) */
export function ranking2_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, category_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "category_setting" + "/" + category_id + "/" + "category_type_setting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","category_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/category_setting/:category_id/edit(.:format) */
export function ranking2_category_setting_edit_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, category_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "category_setting" + "/" + category_id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","category_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/category_setting/new(.:format) */
export function ranking2_category_setting_new_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "category_setting" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/category_type_setting(.:format) */
export function ranking2_category_type_setting_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "category_type_setting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/category_type_setting/new(.:format) */
export function ranking2_category_type_setting_new_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "category_type_setting" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/category_type_setting/:category_type_id/edit(.:format) */
export function ranking2_category_type_setting_edit_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, category_type_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "category_type_setting" + "/" + category_type_id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","category_type_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/chart_setting(.:format) */
export function ranking2_chart_setting_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "chart_setting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/chart_setting/new(.:format) */
export function ranking2_chart_setting_new_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "chart_setting" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/chart_setting/:chart_index/edit(.:format) */
export function ranking2_chart_setting_edit_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, chart_index: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "chart_setting" + "/" + chart_index + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","chart_index","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/chart_setting/download(.:format) */
export function ranking2_chart_setting_download_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "chart_setting" + "/" + "download" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/chart_setting/upload(.:format) */
export function ranking2_chart_setting_upload_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "chart_setting" + "/" + "upload" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/chart(.:format) */
export function ranking2_chart_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "chart" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/common_data_entry(.:format) */
export function ranking2_common_data_entry_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "common_data_entry" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/common_data_entry/:common_data_id/show(.:format) */
export function ranking2_common_data_entry_show_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, common_data_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "common_data_entry" + "/" + common_data_id + "/" + "show" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","common_data_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/invalid_score(.:format) */
export function ranking2_invalid_score_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "invalid_score" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/ranking2/score(.:format) */
export function ranking2_score_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "ranking2" + "/" + "score" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/datastore/metainfo(.:format) */
export function datastore_metainfo_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "datastore" + "/" + "metainfo" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/datastore/metainfo/:data_id(.:format) */
export function datastore_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, data_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "datastore" + "/" + "metainfo" + "/" + data_id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","data_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/utility/application_setting(.:format) */
export function utility_application_setting_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "utility" + "/" + "application_setting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/utility/application_setting/upload(.:format) */
export function utility_application_setting_upload_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "utility" + "/" + "application_setting" + "/" + "upload" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/utility/application_setting/download(.:format) */
export function utility_application_setting_download_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "utility" + "/" + "application_setting" + "/" + "download" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/utility/application_setting/new(.:format) */
export function utility_application_setting_new_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "utility" + "/" + "application_setting" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/utility/application_setting/:setting_index/:key(.:format) */
export function utility_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, setting_index: ScalarType, key: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "utility" + "/" + "application_setting" + "/" + setting_index + "/" + key + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","setting_index","key","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/utility/maintenance_setting(.:format) */
export function utility_maintenance_setting_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "utility" + "/" + "maintenance_setting" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/utility/maintenance_setting/maintenance_mode(.:format) */
export function utility_maintenance_setting_maintenance_mode_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "utility" + "/" + "maintenance_setting" + "/" + "maintenance_mode" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /titles/:title_uuid/:service_env_id/:game_server_id/utility/maintenance_setting/maintenance_announcement(.:format) */
export function utility_maintenance_setting_maintenance_announcement_path(title_uuid: ScalarType, service_env_id: ScalarType, game_server_id: ScalarType, options?: object): string {
  return "/" + "titles" + "/" + title_uuid + "/" + service_env_id + "/" + game_server_id + "/" + "utility" + "/" + "maintenance_setting" + "/" + "maintenance_announcement" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["title_uuid","service_env_id","game_server_id","format"]);
}

/** /api/v1/users/common_titles/:common_title_uuid/registered(.:format) */
export function api_common_title_users_registered_path(common_title_uuid: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + "common_titles" + "/" + common_title_uuid + "/" + "registered" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["common_title_uuid","format"]);
}

/** /api/v1/users/common_titles/:common_title_uuid/unregistered(.:format) */
export function api_common_title_users_unregistered_path(common_title_uuid: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + "common_titles" + "/" + common_title_uuid + "/" + "unregistered" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["common_title_uuid","format"]);
}

/** /api/v1/users/common_titles/:common_title_uuid/roles(.:format) */
export function api_common_title_create_user_roles_path(common_title_uuid: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + "common_titles" + "/" + common_title_uuid + "/" + "roles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["common_title_uuid","format"]);
}

/** /api/v1/users/common_titles/:common_title_uuid/roles(.:format) */
export function api_common_title_update_user_roles_path(common_title_uuid: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + "common_titles" + "/" + common_title_uuid + "/" + "roles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["common_title_uuid","format"]);
}

/** /api/v1/users/common_titles/:common_title_uuid/roles(.:format) */
export function api_common_title_delete_user_roles_path(common_title_uuid: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "users" + "/" + "common_titles" + "/" + common_title_uuid + "/" + "roles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["common_title_uuid","format"]);
}

/** /api/v1/common_titles(.:format) */
export function api_common_titles_path(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "common_titles" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/common_titles/new(.:format) */
export function new_api_common_title_path(options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "common_titles" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/v1/common_titles/:uuid/edit(.:format) */
export function edit_api_common_title_path(uuid: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "common_titles" + "/" + uuid + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["uuid","format"]);
}

/** /api/v1/common_titles/:uuid(.:format) */
export function api_common_title_path(uuid: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "v1" + "/" + "common_titles" + "/" + uuid + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["uuid","format"]);
}

/** /any_login */
export function any_login_path(options?: object): string {
  return "/" + "any_login" + $buildOptions(options, []);
}

/** /any_login/any_login/sign_in(.:format) */
export function any_login_sign_in_path(options?: object): string {
  return "/" + "any_login" + "/" + "any_login" + "/" + "sign_in" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}
