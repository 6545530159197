// This logger should be configured not to run in a production environment.
// See https://github.com/petehunt/webpack-howto#6-feature-flags for how you might turn this
// off for production.
import { createLogger } from 'redux-logger';
import * as Immutable from 'immutable';

export default createLogger({
  stateTransformer: (state) => {
    const newState: { [index: string]: any } = {};

    for (const i of Object.keys(state)) {
      if (Immutable.Iterable.isIterable(state[i])) {
        newState[i] = state[i].toJS();
      } else {
        newState[i] = state[i];
      }
    }

    return newState;
  },
  predicate: () => (
    process.env.NODE_ENV !== 'test' ||
    process.env.NODE_TEST_LOGGER === 'on'
  ),
});
