import * as Immutable from 'immutable';

import ImmutableTypedRecord from 'lib/ImmutableTypedRecord';

import Item from './Item';
import { Role } from '../constants';

export default class Registered extends ImmutableTypedRecord<{
  isLoading: boolean;
  isModifying: boolean;
  alertShow: boolean;
  users: Immutable.List<Item>;
  role: string;
}>({
  isLoading: false,
  isModifying: false,
  alertShow: false,
  users: Immutable.List(),
  role: 'owner',
}) {
}
