import ActionReducer from 'action-reducer';
import ImmutableTypedRecord from 'lib/ImmutableTypedRecord';

// Actions
export const REQUIRE_LOGIN = 'lib/session/REQUIRE_LOGIN';

// Models
export namespace models {
  export class Session extends ImmutableTypedRecord<{
    loginRequired: boolean;
  }>({
    loginRequired: false,
  }) {
  }
}

const { createAction, reducer } = ActionReducer(new models.Session());
export default reducer;

export const actions = {
  requireLogin: createAction(
    REQUIRE_LOGIN,
    state => state.merge({ loginRequired: true }),
  ),
};
