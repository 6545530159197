import { put, take, fork, call, select } from 'redux-saga/effects';
import { ApiResponse, UserTitleRolesApi as Api } from 'lib/Api';

import * as unregistered from '../ducks/unregistered';
import * as registered from '../ducks/registered';
import * as session from 'lib/components/SessionExpiredDialog/duck';
import * as notification from 'lib/components/Notification/duck';
import { InitialStatesType } from '../reducers';

export function apiKeySelector(state: InitialStatesType) {
  return {
    commonTitleUuid: state.app.commonTitleUuid,
  };
}

export function createRolesParamsSelector(state: InitialStatesType) {
  return {
    role: state.unregistered.role,
    ndids: state.unregistered.ndids,
    uids: state.unregistered.users
      .filter(user => user.isSelected).map(user => user.uid).toArray(),
  };
}

export function updateRolesParamsSelector(state: InitialStatesType) {
  return {
    role: state.registered.role,
    ids: state.registered.users.filter(user => user.isSelected).map(user => user.id).toArray(),
  };
}

export function deleteRolesParamsSelector(state: InitialStatesType) {
  return {
    ids: state.registered.users.filter(user => user.isSelected).map(user => user.id).toArray(),
  };
}

export function loginidFilterSelector(state: InitialStatesType) {
  return {
    loginidFilter: state.unregistered.loginidFilter,
  };
}

export function* getUnregisteredUsersRequest() {
  for (; ;) {
    yield take(unregistered.GET_USERS_REQUEST);
    const { loginidFilter } = yield select(loginidFilterSelector);
    const { commonTitleUuid } = yield select(apiKeySelector);
    const response: Api.UnregisteredUserTitleRoleResponse = yield call(() =>
      Api.unregisteredUserTitleRole(commonTitleUuid, { loginidFilter }),
    );
    switch (response.code) {
      case 200:
        yield put(unregistered.actions.getUsersSuccess(response.body));
        break;
      default:
        yield put(unregistered.actions.getUsersFailure(response));
    }
  }
}

export function* createRolesRequest() {
  for (; ;) {
    yield take(unregistered.CREATE_ROLES_REQUEST);
    const { commonTitleUuid } = yield select(apiKeySelector);
    const { ndids, uids, role } = yield select(createRolesParamsSelector);
    const response: Api.CreateUserTitleRoleResponse = yield call(() =>
      Api.createUserTitleRole(commonTitleUuid, { user: { ndids, uids, role } }),
    );
    switch (response.code) {
      case 200:
        yield put(unregistered.actions.createRolesSuccess());
        yield put(notification.actions.notifySuccess(response.body.message));
        yield put(unregistered.actions.getUsersRequest());
        yield put(registered.actions.getUsersRequest());
        break;
      default:
        yield put(unregistered.actions.createRolesFailure(response));
    }
  }
}

export function* updateRolesRequest() {
  for (; ;) {
    yield take(registered.UPDATE_ROLES_REQUEST);
    const { commonTitleUuid } = yield select(apiKeySelector);
    const { ids, role } = yield select(updateRolesParamsSelector);
    const response: Api.UpdateUserTitleRoleResponse = yield call(() =>
      Api.updateUserTitleRole(commonTitleUuid, { user: { role, ids } }),
    );
    switch (response.code) {
      case 200:
        yield put(registered.actions.updateRolesSuccess());
        yield put(notification.actions.notifySuccess(response.body.message));
        yield put(registered.actions.getUsersRequest());
        break;
      default:
        yield put(unregistered.actions.createRolesFailure(response));
    }
  }
}

export function* deleteRolesRequest() {
  for (; ;) {
    yield take(registered.DELETE_ROLES_REQUEST);
    const { commonTitleUuid } = yield select(apiKeySelector);
    const { ids } = yield select(deleteRolesParamsSelector);
    const response: Api.DestroyUserTitleRoleResponse = yield call(() =>
      Api.destroyUserTitleRole(commonTitleUuid, { user: { ids } }),
    );
    switch (response.code) {
      case 200:
        yield put(registered.actions.deleteRolesSuccess());
        yield put(notification.actions.notifySuccess(response.body.message));
        yield put(unregistered.actions.getUsersRequest());
        yield put(registered.actions.getUsersRequest());
        break;
      default:
        yield put(registered.actions.deleteRolesFailure(response));
    }
  }
}

export function* getRegisteredUsersRequest() {
  for (; ;) {
    yield take(registered.GET_USERS_REQUEST);
    const { commonTitleUuid } = yield select(apiKeySelector);
    const response: Api.RegisteredUserTitleRoleResponse = yield call(() =>
      Api.registeredUserTitleRole(commonTitleUuid),
    );
    switch (response.code) {
      case 200:
        yield put(registered.actions.getUsersSuccess(response.body));
        break;
      default:
        yield put(registered.actions.deleteRolesFailure(response));
    }
  }
}

export function* notifyErrors() {
  for (; ;) {
    const { payload }: { payload: ApiResponse } = yield take([
      unregistered.GET_USERS_FAILURE,
      registered.GET_USERS_FAILURE,
      unregistered.CREATE_ROLES_FAILURE,
      registered.UPDATE_ROLES_FAILURE,
      registered.DELETE_ROLES_FAILURE,
    ]);
    const response = payload;

    switch (response.code) {
      case 401:
        yield put(session.actions.requireLogin());
        break;
      case 403:
        yield put(notification.actions.notifyError('Forbidden'));
        break;
      default:
        if (response.body.name === 'UnexpectedResponseError') {
          yield put(notification.actions.notifyError(
            response.body.response.statusText));
        } else {
          yield put(notification.actions.notifyError('Unexpected Response Error'));
        }
        break;
    }
  }
}

export default function* rootSaga() {
  yield fork(getUnregisteredUsersRequest);
  yield fork(createRolesRequest);
  yield fork(updateRolesRequest);
  yield fork(deleteRolesRequest);
  yield fork(getRegisteredUsersRequest);
  yield fork(notifyErrors);
}
