import * as Immutable from 'immutable';
import { take, fork, call, put } from 'redux-saga/effects';
import * as app from '../ducks/app';

function readFileAsText(file: File) {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsText(file);
  });
}

export function* readFile() {
  for (; ;) {
    const { payload }: { payload: File } = yield take(app.READ_FILE);
    const csvText: string = yield call(readFileAsText, payload);
    const rows = csvText.trim().replace('\r\n', '\n').replace('\r', '\n').split('\n');
    const csv = Immutable.List(rows.map(row => Immutable.List(row.split(','))));
    yield put(app.actions.readFileSuccess(csv));
  }
}

export default function* rootSaga() {
  yield fork(readFile);
}
