// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".app-javascript-bundles-UserTitleRoleEditor-components-App-style__root--1NjVX{height:calc(100vh - 140px);position:relative}.app-javascript-bundles-UserTitleRoleEditor-components-App-style__container--1nuZz{height:100%}.app-javascript-bundles-UserTitleRoleEditor-components-App-style__separator--3Jefi{opacity:.2;border-right:1px solid #000;margin:0 5px}", "",{"version":3,"sources":["/app/app/javascript/bundles/UserTitleRoleEditor/components/App/style.scss"],"names":[],"mappings":"AAAA,8EACE,0BAAA,CACA,iBAAA,CAGF,mFACE,WAAA,CAGF,mFACE,UAAA,CACA,2BAAA,CACA,YAAA","file":"style.scss","sourcesContent":[".root {\n  height: calc(100vh - 140px);\n  position: relative;\n}\n\n.container {\n  height: 100%;\n}\n\n.separator {\n  opacity: .2;\n  border-right: 1px solid #000;\n  margin: 0 5px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "app-javascript-bundles-UserTitleRoleEditor-components-App-style__root--1NjVX",
	"container": "app-javascript-bundles-UserTitleRoleEditor-components-App-style__container--1nuZz",
	"separator": "app-javascript-bundles-UserTitleRoleEditor-components-App-style__separator--3Jefi"
};
module.exports = exports;
