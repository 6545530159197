import * as React from 'react';
import { Provider } from 'react-redux';

import createStore from '../../store';
import { AppContainer } from '../../components/App';
import { InitialProps } from '../../reducers';

const UserTitleRoleEditorApp = (props: InitialProps) => {
  const store = createStore(props);

  const reactComponent = (
    <Provider store={store}>
      <AppContainer />
    </Provider>
  );
  return reactComponent;
};

export default UserTitleRoleEditorApp;
